import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import MealMentor from '../pages/MealMentor';
import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage';
import OtpPage from '../pages/OtpPage';
import FinishPage from '../pages/FinishPage';
import Dashboard from '../pages/Dashboard';
import ResponsePage from '../pages/ResponsePage';
import Recipe from '../pages/Recipe';
import ThankYouPage from '../pages/ThankYouPage';
import PaymentPage from '../pages/PaymentPage';
import RecipeTitlePage from '../pages/RecipeTitlePage';
import RecipeGenrator from '../pages/RecipeGenrator';
import PlanPage from '../pages/PlanPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import TermandCondition from '../pages/TermandCondition';
import UpdatePage from '../pages/UpdatePage';
import UnSubscribe from '../pages/UnSubscribe';
import GeneralQuestionPage from '../pages/GeneralQuestionPage';
import Category from './resuableComponents/Category';
import CategoryPage from '../pages/CategoryPage';
const MainRouter = () => {
	return (
		<div>
			<Routes>
				<Route path='/' exact element={<HomePage />} />
				<Route path='/dashboard' exact element={<Dashboard />} />
				<Route
					path='/generalQuestion'
					exact
					element={<GeneralQuestionPage />}
				/>
				<Route path='/category' exact element={<CategoryPage />} />
				<Route path='/profile' exact element={<UpdatePage />} />
				<Route path='/login' exact element={<LoginPage />} />
				<Route path='/unsubscribe' exact element={<UnSubscribe />} />
				<Route path='/register' exact element={<RegisterPage />} />
				<Route path='/getstarted' exact element={<FinishPage />} />
				<Route path='/otp' exact element={<OtpPage />} />
				<Route path='/mealmentor' exact element={<MealMentor />} />
				<Route path='/response' exact element={<ResponsePage />} />
				<Route path='/resp' exact element={<ThankYouPage />} />
				<Route path='/payment' exact element={<PaymentPage />} />
				<Route path='/recipetitle' exact element={<RecipeTitlePage />} />
				<Route path='/recipegenerator' exact element={<RecipeGenrator />} />
				<Route path='/recipe' exact element={<Recipe />} />
				<Route path='/plan' exact element={<PlanPage />} />
				<Route path='/privacypolicy' exact element={<PrivacyPolicyPage />} />
				<Route path='/termandcondition' exact element={<TermandCondition />} />
				<Route path='*' exact element={<h1>Page Not found</h1>} />
			</Routes>
		</div>
	);
};

export default MainRouter;
